import {
  HomePageToolkit,
} from '@backstage/plugin-home';
import { Content, Page } from '@backstage/core-components';
import { HomePageSearchBar } from '@backstage/plugin-search';
import { SearchContextProvider } from '@backstage/plugin-search-react';
import { Grid, makeStyles } from '@material-ui/core';
import BookIcon from '@material-ui/icons/Book';
import LibraryBooks from '@material-ui/icons/LibraryBooks';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import React from 'react';

import {
  HomePageRequestedReviewsCard,
  HomePageYourOpenPullRequestsCard,
} from '@roadiehq/backstage-plugin-github-pull-requests';

const useStyles = makeStyles(theme => ({
  searchBar: {
    display: 'flex',
    maxWidth: '60vw',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[1],
    padding: '8px 0',
    borderRadius: '50px',
    margin: 'auto',
  },
}));

export const HomePage = () => {
  const classes = useStyles();

  return (
    <SearchContextProvider>
      <Page themeId="home">
        <Content>
          <Grid container justifyContent="center" spacing={6}>
            <h1>Inventory</h1>
            <Grid container item xs={12} alignItems="center" direction="row">
              <HomePageSearchBar
                classes={{ root: classes.searchBar }}
                placeholder="Search"
              />
            </Grid>
            <Grid container item xs={12} justifyContent="center">
              <Grid item xs={8} md={8}>
                <HomePageToolkit
                  tools={[
                    {
                      url: "/docs/default/system/cloud-platform/guide_how_to_onboard_platform/#add-a-user-account",
                      label: "Add a user to the Platform",
                      icon: <PersonAddIcon />,
                    },
                    {
                      url: "/docs/default/component/guidelines/",
                      label: "Guidelines",
                      icon: <BookIcon />,
                    },
                    {
                      url: "/docs/default/component/rfcs",
                      label: "Requests For Comments",
                      icon: <BookIcon />,
                    },
                    {
                      url: "/docs/default/system/cloud-platform/",
                      label: "Cloud Platform Docs",
                      icon: <BookIcon />,
                    },
                    {
                      url: "/docs/default/component/coop-playbook/",
                      label: "Coop Playbook",
                      icon: <LibraryBooks />,
                    }
                  ]}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <HomePageRequestedReviewsCard />
              </Grid>
              <Grid item md={6} xs={12}>
                <HomePageYourOpenPullRequestsCard />
              </Grid>
            </Grid>
          </Grid>
        </Content>
      </Page>
    </SearchContextProvider>
  );

};

import React from 'react';
import {
  createScaffolderLayout,
  LayoutTemplate,
  scaffolderPlugin,
} from '@backstage/plugin-scaffolder';
import { Link } from '@backstage/core-components';

const DisplayGitHubConfig: LayoutTemplate = ({
  title,
  description,
  properties,
}) => {
  const getValue = (key: string): string =>
    properties.find(o => o.name === key)?.content.props.formData;

  const repoUrl = getValue('repoUrl');
  const descriptionValue = getValue('description');
  const ownerValue = getValue('owner');
  const repoName = new URLSearchParams(
    new URL('https://' + repoUrl).search,
  ).get('repo');
  const moduleName = repoName?.replace(/-/g, '_');

  const githubConfig =
`module "${moduleName}" {
  count   = var.environment == "production" ? 1 : 0
  source  = "terraform.coop.no/coopnorge/repos/github"
  version = "3.1.0"

  name          = "${repoName}"
  description   = "${descriptionValue}"
  template      = null
  status_checks = []

  teams = {
    "${ownerValue}" = {
      permission = "push"
    }
  }
}`;

  return (
    <>
      {title !== undefined && <h1>{title}</h1>}
      {description != undefined && <p>{description}</p>}
      <code>
        <pre>{githubConfig}</pre>
      </code>
      Reference:
      <ul>
        <li>
          <Link
            to="https://github.com/coopnorge/cloud-projects"
            target="_blank"
          >
            cloud-projects
          </Link>
        </li>
        <li>
          <Link
            to="https://inventory.internal.coop/docs/default/system/cloud-platform/dev_build_deploy/github/guide_github_create_repository/"
            target="_blank"
          >
            How to create GitHub repository
          </Link>
        </li>
      </ul>
    </>
  );
};

export const DisplayGitHubConfigLayout = scaffolderPlugin.provide(
  createScaffolderLayout({
    name: 'DisplayGitHubConfig',
    component: DisplayGitHubConfig,
  }),
);
